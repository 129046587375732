
import { defineComponent, ref, computed } from 'vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import CampaignsEmailDetailsDevice from '@/components/pages/campaigns/details/emails/overview/CampaignsEmailDetailsDevice.vue'
import { campaignsEmailDevicesOpens, campaignsEmailDevicesClicks } from '@/definitions/campaigns/data'

export default defineComponent({
  components: {
    TmToggleButtons,
    TmButton,
    ReportGraph,
    CampaignsEmailDetailsDevice,
  },
  setup() {
    const currentGraph = ref('opens')
    const computedData = computed(() => currentGraph.value === 'opens' ? campaignsEmailDevicesOpens : campaignsEmailDevicesClicks)

    return {
      computedData,
      currentGraph,
    }
  },
})
