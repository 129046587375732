
import { defineComponent } from 'vue'
import CampaignsDetailsBlock from '@/components/pages/campaigns/details/CampaignsDetailsBlock.vue'
import CampaignsDetailsRateBlock from '@/components/pages/campaigns/details/CampaignsDetailsRateBlock.vue'
import ReportEmpty from '@/components/shared/templates/reports/ReportEmpty.vue'
import { campaignsEmailEngagement, campaignsEmailEngagementEmpty } from '@/definitions/campaigns/data'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ReportEmpty,
    CampaignsDetailsRateBlock,
    CampaignsDetailsBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
      campaignsEmailEngagementEmpty,
      campaignsEmailEngagement,
    }
  },
})
