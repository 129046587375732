
import { defineComponent } from 'vue'
import CampaignsEmailDetailsDelivery from '@/components/pages/campaigns/details/emails/overview/CampaignsEmailDetailsDelivery.vue'
import CampaignsEmailDetailsEngagement from '@/components/pages/campaigns/details/emails/overview/CampaignsEmailDetailsEngagement.vue'
import CampaignsEmailDetailsAnalytics from '@/components/pages/campaigns/details/emails/overview/CampaignsEmailDetailsAnalytics.vue'

export default defineComponent({
  components: {
    CampaignsEmailDetailsEngagement,
    CampaignsEmailDetailsAnalytics,
    CampaignsEmailDetailsDelivery,
  },
})
