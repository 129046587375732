
import { defineComponent, ref } from 'vue'
import CampaignsDetailsBlock from '@/components/pages/campaigns/details/CampaignsDetailsBlock.vue'
import CampaignsDetailsGeography from '@/components/pages/campaigns/details/CampaignsDetailsGeography.vue'
import CampaignsDetailsTimeline from '@/components/pages/campaigns/details/CampaignsDetailsTimeline.vue'
import { getCampaignsEmailGeographyChartData, getEmailCampaignsTimelineChartData } from '@/definitions/shared/charts/campaigns/data'
import CampaignsEmailDetailsDevices from '@/components/pages/campaigns/details/emails/overview/CampaignsEmailDetailsDevices.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    CampaignsEmailDetailsDevices,
    CampaignsDetailsTimeline,
    CampaignsDetailsGeography,
    CampaignsDetailsBlock,
  },
  setup() {
    const currentGraph = ref('opens')
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
      currentGraph,
      getEmailCampaignsTimelineChartData,
      getCampaignsEmailGeographyChartData,
    }
  },
})
