import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "campaigns-email-details-device" }
const _hoisted_2 = { class: "caption-12 neutral--text mb-4" }
const _hoisted_3 = { class: "d-flex mb-4 align-center" }
const _hoisted_4 = { class: "campaigns-email-details-device__percent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_progress = _resolveComponent("tm-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      size: "xxxLarge",
      class: "mb-4",
      style: _normalizeStyle({
        color: _ctx.computedColor,
      })
    }, null, 8, ["name", "style"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.percent) + "% ", 1),
      _createVNode(_component_tm_badge, { color: _ctx.color }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.counter), 1)
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _createVNode(_component_tm_progress, {
      value: _ctx.percent / 100,
      color: _ctx.computedColor,
      size: "16px"
    }, null, 8, ["value", "color"])
  ]))
}