
import { defineComponent, computed } from 'vue'
import type { PropType } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  components: {
    TmProgress,
    TmBadge,
  },
  props: {
    percent: {
      type: Number,
    },
    counter: {
      type: String,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String as PropType<'blue' | 'green' | 'orange'>,
    },
  },
  setup(props) {
    const computedColor = computed(() => {
      switch (props.color) {
        case 'green': return rgbToHex(styleVars.green500)
        case 'orange': return rgbToHex(styleVars.orange500)
        default: return rgbToHex(styleVars.blue500)
      }
    })

    return {
      computedColor,
    }
  },
})
