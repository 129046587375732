
import { defineComponent } from 'vue'
import { campaignEmailDeliveryStats } from '@/definitions/campaigns/data'
import CampaignsDetailsDelivery from '@/components/pages/campaigns/details/CampaignsDetailsDelivery.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    CampaignsDetailsDelivery,
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
      campaignEmailDeliveryStats,
    }
  },
})
